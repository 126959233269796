import React, {  } from 'react'

const MovieList = ({ title, movies, onMovieClick }) => (
    <section className="py-5 pl-4 pr-4">
        <div className="container">
            <h2 className="mb-4 text-gray-800 font-bold text-2xl">{title}</h2>
            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-4">
                {movies && movies.map((movie, index) => (
                    <div key={index} className="movie-item" onClick={() => onMovieClick(movie)}>
                        <img src={movie.thumb} className="w-full h-auto rounded shadow-md" alt={movie.title} />
                    </div>
                ))}
            </div>
        </div>
    </section>
)

export default MovieList;