import { useEffect, useState } from "react";
import NavigationBar from "../segments/navigationBar";
import './Player.css'
import 'react-toastify/dist/ReactToastify.css';
import MovieList from "../segments/MovieList";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "../ui/dialog";
import { Link } from "react-router-dom";
import { Button } from "../ui/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Environment } from '../../context/Environment';

const Podcast = () => {

    const [podcast, setPodcasts] = useState([]);

    const [selectedMovie, setSelectedMovie] = useState(null)

    useEffect(() => {

        const fetchMovies = async () => {

            const response = await fetch( Environment.getApiUrl() + '/podcast');

            const { data }: any = await response.json();

            setPodcasts(data);

        }

        fetchMovies();

    }, []);

    const handleMovieClick = (movie) => {
        setSelectedMovie(movie)
    }

    return (
        <div className="bg-gray-100 min-h-screen text-gray-800 overflow-auto">

            <header>
                <NavigationBar />
            </header>

            <main className='container mx-auto p-2'>
                
                {podcast && podcast.map((movie, index) => (
                    <div key={index}>
                        <MovieList title={movie.category.name} movies={movie.audios} onMovieClick={handleMovieClick} />
                    </div>
                ))}

            </main>

            <Dialog open={!!selectedMovie} onOpenChange={() => setSelectedMovie(null)}>
                <DialogContent className="{bg-white text-gray-800">
                    <DialogHeader>
                        <DialogTitle className="text-2xl font-bold text-gray-900">{selectedMovie?.title}</DialogTitle>
                    </DialogHeader>
                    <DialogDescription>

                        <img src={selectedMovie?.thumb}></img>

                        <p className="mb-4 text-gray-700">{selectedMovie?.description}</p>
                        <p className="mb-2 text-gray-600"><strong>A&ntilde;o:</strong> {selectedMovie?.year}</p>
                        <p className="mb-2 text-gray-600"><strong>Creador:</strong> {selectedMovie?.author}</p>
                        <p className="text-gray-600"><strong>Category:</strong> {selectedMovie?.gender}</p>
                    </DialogDescription>

                    <DialogFooter>

                        <Link to={`/player/${selectedMovie?.id}`}>
                            <Button className="bg-blue-600 text-white hover:bg-red-700">
                                <FontAwesomeIcon icon={faPlayCircle} /> Ver ahora
                            </Button>
                        </Link>

                        <Button className="bg-red-600 text-white hover:bg-red-700" onClick={() => setSelectedMovie(null)}>
                            <FontAwesomeIcon icon={faTimesCircle} /> Cerrar
                        </Button>

                    </DialogFooter>

                </DialogContent>
            </Dialog>

        </div>
    )
}
export default Podcast;