import { useEffect, useState } from 'react'
import { Button } from "../ui/button"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "../ui/dialog"
import NavigationBar from '../segments/navigationBar';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import Footer from '../ui/footer';
import { Environment } from '../../context/Environment';
import MovieList from '../segments/MovieList';

interface promoModel {
    title: string;
    description: string;
    creator: string;
    gender: string;
    thumb: string;
    type: string;
    year: string;
    id: string;
    sid: string;
}

export default function Component() {

    const [selectedMovie, setSelectedMovie] = useState(null)
    const [movies, setMovies] = useState([]);
    const [featured, setFeatured] = useState([]);
    const [promo, setPromo] = useState<promoModel>();
    const [podcasts, setPodcasts] = useState([]);

    useEffect(() => {

        const fetchMovies = async () => {

            const response = await fetch( Environment.getApiUrl() + '/videos');

            const { data }: any = await response.json();

            setMovies(data.explore);

            setFeatured(data.featured);

            setPodcasts(data.podcast_free);

            const indiceAleatorio = Math.floor(Math.random() * (data.featured).length);

            setPromo(data.featured[indiceAleatorio]);

        }

        fetchMovies();


    }, []);


    const handleMovieClick = (movie) => {
        setSelectedMovie(movie)
    }

    return (
        <div className="bg-gray-100 min-h-screen text-gray-800 overflow-auto">

            <header>
                <NavigationBar />
            </header>

            {promo ? (
                <main>
                    <section className="hero relative h-[80vh] bg-cover bg-center" style={{ backgroundImage: `url(${promo.thumb})` }}>
                        <div className="absolute inset-0 bg-white bg-opacity-60 flex items-center">
                            <div className="container mx-auto px-4">
                                <h1 className="text-5xl font-bold mb-4 text-gray-900">{promo.title}</h1>
                                <p className="text-xl mb-4 max-w-2xl text-gray-800">{promo.description}</p>

                                <Link to={`/player/${promo.id}`}>
                                    <Button className="bg-red-600 text-white hover:bg-red-700 mr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 mr-2">
                                            <path fillRule="evenodd" d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z" clipRule="evenodd" />
                                        </svg>
                                        Ver
                                    </Button>
                                </Link>

                                <Button variant="outline" className="text-gray-800 border-gray-800 hover:bg-gray-200" onClick={() => handleMovieClick(movies[0])}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 mr-2">
                                        <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
                                    </svg>
                                    Más Info
                                </Button>
                            </div>
                        </div>
                    </section>

                    <section className='container mx-auto'>

                        <MovieList title="Los más vistos" movies={featured} onMovieClick={handleMovieClick} />

                        <MovieList title="Te podrían interesar" movies={movies} onMovieClick={handleMovieClick} />

                        <MovieList title="Podcast" movies={podcasts} onMovieClick={handleMovieClick} />

                    </section>

                </main>
            ) : (<main> </main>)}

            <Dialog open={!!selectedMovie} onOpenChange={() => setSelectedMovie(null)}>
                <DialogContent className="{bg-white text-gray-800">
                    <DialogHeader>
                        <DialogTitle className="text-2xl font-bold text-gray-900">{selectedMovie?.title}</DialogTitle>
                    </DialogHeader>
                    <DialogDescription>

                        <img src={selectedMovie?.thumb} alt='habdi.net'></img>

                        <p className="mb-4 text-gray-700">{selectedMovie?.description}</p>
                        <p className="mb-2 text-gray-600"><strong>A&ntilde;o:</strong> {selectedMovie?.year}</p>
                        <p className="mb-2 text-gray-600"><strong>Creador:</strong> {selectedMovie?.author}</p>
                        <p className="text-gray-600"><strong>Category:</strong> {selectedMovie?.gender}</p>
                    </DialogDescription>

                    <DialogFooter>

                        <Link to={`/player/${selectedMovie?.id}`}>
                            <Button className="bg-blue-600 text-white hover:bg-red-700">
                                <FontAwesomeIcon icon={faPlayCircle} /> Ver ahora
                            </Button>
                        </Link>

                        <Button className="bg-red-600 text-white hover:bg-red-700" onClick={() => setSelectedMovie(null)}>
                            <FontAwesomeIcon icon={faClose} /> Cerrar
                        </Button>
                    </DialogFooter>

                </DialogContent>
            </Dialog>

            <style>
                {`
                    body {
                    background-color: #f3f4f6;
                    color: #1f2937;
                    width:100%;
                    }
                    .movie-item {
                    transition: transform 0.2s;
                    cursor: pointer;
                    }
                    .movie-item:hover {
                    transform: scale(1.05);
                    }
                `}
            </style>

            <Footer></Footer>

        </div>
    )
}