import React, { useEffect, useState } from 'react'
import NavigationBar from '../segments/navigationBar';
import { Link, useParams } from 'react-router-dom';
import MovieList from '../segments/MovieList';

import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from '../ui/dialog';
import { Button } from '../ui/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faPlayCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Environment } from '../../context/Environment';

const Videos = () => {

    const [movies, setGenders] = useState([])

    const [catName, setCategory] = useState("");

    const { category } = useParams();

    const [selectedMovie, setSelectedMovie] = useState(null)

    useEffect(() => {

        const fetchMovies = async () => {

            const response = await fetch( Environment.getApiUrl() +  '/explore/' + category);

            const { ok, data }: any = await response.json();

            setGenders(data.explore);

            setCategory(data.gender);

            console.log(catName, movies);

        }

        fetchMovies();

    }, []);

    const handleMovieClick = (movie) => {
        setSelectedMovie(movie)
    }

    return (
        <div className="bg-gray-100 min-h-screen text-gray-800 overflow-auto">

            <header>
                <NavigationBar />
            </header>

            <main className='container mx-auto'>

                {!!catName ? (
                    <MovieList title={`Videos de la categoría ${catName} `} movies={movies} onMovieClick={handleMovieClick} />

                ) : (
                    <div className='mx-auto py-4 px-4 justify-center items-center flex flex-col'>

                        Seguimos trabajando para proporcionarte más contenido de calidad.

                        <Link className='text-blue-900 font-bold' to="/categories">Porque no sigues explorando más ?</Link>

                    </div>
                )}

                <Dialog open={!!selectedMovie} onOpenChange={() => setSelectedMovie(null)}>
                    <DialogContent className="{bg-white text-gray-800">
                        <DialogHeader>
                            <DialogTitle className="text-2xl font-bold text-gray-900">{selectedMovie?.title}</DialogTitle>
                        </DialogHeader>
                        <DialogDescription>

                            <img src={selectedMovie?.thumb}></img>

                            <p className="mb-4 text-gray-700">{selectedMovie?.description}</p>
                            <p className="mb-2 text-gray-600"><strong>A&ntilde;o:</strong> {selectedMovie?.year}</p>
                            <p className="mb-2 text-gray-600"><strong>Creador:</strong> {selectedMovie?.author}</p>
                            <p className="text-gray-600"><strong>Category:</strong> {selectedMovie?.gender}</p>
                        </DialogDescription>

                        <DialogFooter>
                        
                            <Link to={`/player/${selectedMovie?.id}`}>
                                <Button className="bg-blue-600 text-white hover:bg-red-700">
                                    <FontAwesomeIcon icon={faPlayCircle} /> Ver ahora
                                </Button>
                            </Link>

                            <Button className="bg-red-600 text-white hover:bg-red-700" onClick={() => setSelectedMovie(null)}>
                                <FontAwesomeIcon icon={faTimesCircle} /> Cerrar
                            </Button>
                            
                        </DialogFooter>

                    </DialogContent>
                </Dialog>

            </main>

        </div>
    )
}

export default Videos;
