import { useState } from 'react';
import axios from 'axios';
import NavigationBar from '../segments/navigationBar';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import { Environment } from '../../context/Environment';

const Login = ({ setAuth }) => {

    const history = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [disabledLogin, setDisabledLogin] = useState(false);

    const handleLogin = async (e) => {

        setDisabledLogin(true);

        e.preventDefault();

        try {

            axios.post( Environment.getApiUrl() + '/login', {
                email,
                password,
            }).then(response => {

                const { ok, data, message } = response.data;

                if (ok == false) {
                    
                    Swal.fire({
                        title: 'ops!',
                        text: message,
                        icon: 'success'
                    });

                } else {

                    localStorage.setItem('token_habdi010924', JSON.stringify(data));

                    history("/profile");

                }

                setDisabledLogin(false);

            });

        } catch (err) {
            setError('Invalid credentials');
        }


    };

    return (
        <div className="bg-gray-100 min-h-screen text-gray-800 overflow-auto">

            <header>
                <NavigationBar />
            </header>

            <main>

                <div className="flex items-center justify-center mt-[25vh] bg-gray-100">
                    <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-md">
                        <h2 className="text-2xl font-bold mb-2">Login</h2>
                        <p className="text-gray-600 mb-6">Ingresa tus credenciales para acceder a tu cuenta</p>
                        <form className="space-y-4">
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                                <input
                                    id="email"
                                    type="email"
                                    required
                                    className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}

                                />
                            </div>
                            <div>
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                                <input
                                    id="password"
                                    type="password"
                                    required
                                    className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                    placeholder="Enter your password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <button
                                type="submit"
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={handleLogin} disabled={disabledLogin}>
                                Validar datos
                            </button>

                            <div className='flex items-center justify-center flex-col'>
                                <Link to="/register" className='text-purple py-5 px-5 font-bold'>Crear cuenta nueva</Link>
                            </div>

                        </form>
                    </div>
                </div>

            </main>

        </div>
    )
};

export default Login;
