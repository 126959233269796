import { faBell, faSearch, faUser, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { useNavigate, } from 'react-router-dom';

const NavigationBar = ({ }) => {

    const history = useNavigate();

    const [menuOpen, setMenuOpen] = useState(false);

    const [searchOpen, setsearchOpen] = useState(false);

    const [isLogged, setLogged] = useState(false);

    const [searchField, setSearchField] = useState("");

    const token = localStorage.getItem("token_habdi010924");

    useEffect(() => {

        if (token != null) {
            setLogged(true)
        }

    }, [token]);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };


    function handleSearchStatus(): void {
        setsearchOpen(!searchOpen);
    }

    function handleLoginStatus(): void {

        const token = localStorage.getItem("token_habdi010924");

        if (token == null) {
            history('/login');
        } else {
            history('/profile');
        }

    }

    function handleLogout(): void {

        localStorage.clear();

        history('/');

    }

    function handleSearch(event): void {

        event.preventDefault();

        localStorage.setItem('searchTerm', searchField);

        history('/search/' + searchField);

    }

    function handleSearchField(e): void {
        setSearchField(e.target.value);
    }

    return (
        <nav className="bg-white shadow-md p-4">

            <div className="container mx-auto flex justify-between items-center">

                <a className="navbar-brand" href="/">
                    <img src="https://mirrorlinux.net/hb3.png" alt="Conocimiento para la vida" style={{ maxHeight: '3em' }} />
                </a>

                <button className="lg:hidden" onClick={toggleMenu}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
                    </svg>
                </button>

                <div className="hidden lg:flex items-left space-x-4">

                    <a className="text-gray-700 hover:text-gray-900" href="/">Inicio</a>
                    <a className="text-gray-700 hover:text-gray-900" href="/categories">Explorar</a>
                    <a className="text-gray-700 hover:text-gray-900" href="/Podcast">Podcast</a>

                    <i className="fas fa-home"></i>

                    <a className="text-gray-700 hover:text-gray-900" href="#" onClick={handleSearchStatus}>
                        <FontAwesomeIcon icon={faSearch} />
                    </a>
                    <a className="text-gray-700 hover:text-gray-900" href="#"><FontAwesomeIcon icon={faBell} /></a>

                    <a className="text-gray-700 hover:text-gray-900" href="#" onClick={handleLoginStatus}>
                        <FontAwesomeIcon icon={faUser} />
                    </a>

                    {isLogged ? (
                        <a className="text-gray-700 hover:text-gray-900" href="#" onClick={handleLogout}>
                            <FontAwesomeIcon icon={faSignOut} />
                        </a>) : ([])}

                </div>
            </div>

            {menuOpen ? (
                <div className=" px-4 py-4 bg-gray-100">
                    <ul className="flex bg-dark pl-1 flex-col gap-4">
                        <li><a className="text-gray-700 hover:text-gray-900" href="/">Inicio</a></li>
                        <li><a className="text-gray-700 hover:text-gray-900" href="/categories">Explorar</a></li>
                        <li><a className="text-gray-700 hover:text-gray-900" href="/Podcast">Podcast</a></li>
                        <li><a className="text-gray-700 hover:text-gray-900" href="#" onClick={handleLoginStatus}>Cuenta</a></li>
                        {isLogged ? (<li><a className="text-gray-700 hover:text-gray-900" href="#" onClick={handleLogout}>Cerrar Sesion</a></li>) : ([])}
                    </ul>
                </div>
            ) : ([])}

            {searchOpen ? (

                <div className="flex justify-center items-center mt-10">

                    <form className="flex items-center w-full max-w-md">

                        <input
                            type="text"
                            placeholder="Buscar..."
                            value={searchField}
                            onChange={handleSearchField}
                            className="w-full px-4 py-2 border border-gray-300 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        />

                        <button
                            type="submit"
                            onClick={handleSearch}
                            className="bg-blue-600 text-white px-4 py-2 rounded-r-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        >
                            Buscar
                        </button>
                    </form>
                </div>

            ) : ([])}

        </nav>
    )
}

export default NavigationBar;
