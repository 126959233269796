import { faCheckCircle, faMusic, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const Footer = ({ }) => {

  return (
    <footer className="bg-gray-100 text-black p-4 relative bottom-0 w-full">

      <h2 className="mx-auto flex flex-col px-5 py-5 items-center justify-left rounded bg-gray-200">
        Siguenos en nuestras redes sociales
      </h2>

      <div className="container mx-auto">

        <div className="flex justify-around">

          <a href="https://www.facebook.com/profile.php?id=100092517194704&mibextid=ZbWKwL" className="hover:text-gray-300 py-5 px-5" target="_blank">
            <FontAwesomeIcon icon={faUser} /> Facebook
          </a>

          <a href="https://www.tiktok.com/@habdi.net?_t=8pPtlDFB3FV&_r=1" className="hover:text-gray-300 py-5 px-5" target="_blank">
            <FontAwesomeIcon icon={faMusic} /> TikTok
          </a>

          <a href="https://www.instagram.com/habdi_net?igsh=MTh0Y3QxazA1NXJj" className="hover:text-gray-300 py-5 px-5" target="_blank">
            <FontAwesomeIcon icon={faCheckCircle} /> Instagram
          </a>

        </div>

      </div>

      <h2 className="mx-auto flex flex-col px-5 py-5 items-center justify-left rounded bg-gray-300">
        Necesitas ayuda ?
      </h2>

      <div className="container mx-auto">

        <div className="flex justify-around">

          <Link to="mailto:habdi.net.2@gmail.com" className="font-bold text-blue py-5 px-5"> habdi.net.2@gmail.com </Link>

        </div>

      </div>

      <h2 className="mx-auto flex flex-col px-5 py-5 items-center justify-left rounded bg-gray-400 text-white">
        Habdi.net @ 2024 - Algunos derechos reservados
      </h2>

    </footer>
  )
};

export default Footer;