import { useEffect, useState } from "react";
import NavigationBar from "../segments/navigationBar";
import { Button } from "../ui/button";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "../ui/card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faL, faSave, faUser } from "@fortawesome/free-solid-svg-icons";
import { Environment } from "../../context/Environment";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const Register = () => {

    const history = useNavigate();
    
    const frmModel: any = {
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        country: "",
        password: "",
        confirmPassword: ""
    };

    const [formData, setFormData] = useState(Object.assign({}, frmModel));

    const [errors, setErrors] = useState(Object.assign({}, frmModel));

    const [countries, setCountries] = useState([]);

    const [isLoading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((prev) => ({ ...prev, [name]: value }))
    }

    const validateStep = () => {

        const newErrors = Object.assign({}, frmModel);


        if (!formData.email) newErrors.email = "El correo electrónico es obligatorio"
        if (!formData.firstName) newErrors.firstName = "El nombre es obligatorio"
        if (!formData.lastName) newErrors.lastName = "El apellido es obligatorio"
        if (!formData.phone) newErrors.phone = "El teléfono es obligatorio"
        if (!formData.country) newErrors.country = "El país es obligatorio"
        if (!formData.password) newErrors.password = "La contraseña es obligatoria"
        if (formData.password !== formData.confirmPassword) newErrors.confirmPassword = "Las contraseñas no coinciden"

        setErrors(newErrors)

        return Object.values(newErrors).filter(x => x !== '').length === 0;

    }

    const handleSubmit = async (e) => {

        e.preventDefault()

        const validate = validateStep();

        if (validate == true) {

            setLoading(true);

            formData.nickname = formData.email;
            formData.code = "version2";

            const response = await fetch(Environment.getApiUrl() + '/validate', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            const { ok, data, message }: any = await response.json();

            setLoading(false);

            if (ok == true) {
                
                localStorage.setItem('token_habdi010924', JSON.stringify(data));

                history('/profile');

            }
            else {
                Swal.fire({
                    title: 'Sucedio un error!',
                    text: message,
                    icon: 'warning'
                });
            }
            console.log(82, ok, data, message);

        }

    }

    useEffect(() => {

        const getcountries = async () => {

            const response = await fetch(Environment.getApiUrl() + '/paises', {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Accept': 'application/json'
                }
            });

            const { ok, data }: any = await response.json();

            if (ok == true) {
                setCountries(data);
            }

        };

        getcountries();

    }, []);

    return (
        <div className="bg-gray-100 min-h-screen text-gray-800 overflow-auto">

            <header>
                <NavigationBar />
            </header>

            <main className="relative flex">

                <Card className="w-full max-w-lg mx-auto py-5 mt-10 bg-white mx-auto">

                    <CardHeader>
                        <CardTitle className="text-2xl font-bold text-center">
                            <FontAwesomeIcon icon={faUser} /> Registro de usuario
                        </CardTitle>
                    </CardHeader>

                    <CardContent>

                        <form onSubmit={handleSubmit} className="w-full">

                            <div className="mt-3">
                                <Label htmlFor="email">Correo</Label>
                                <Input
                                    id="email"
                                    name="email"
                                    type="email"
                                    placeholder="hola@mundo.com"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                {errors.email && <p className="text-sm text-red-500">{errors.email}</p>}
                            </div>

                            <div className="mt-3">
                                <Label htmlFor="firstName">Nombre</Label>
                                <Input
                                    id="firstName"
                                    name="firstName"
                                    placeholder="Juan"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                />
                                {errors.firstName && <p className="text-sm text-red-500">{errors.firstName}</p>}
                            </div>
                            <div className="space-y-2">
                                <Label htmlFor="lastName">Apellidos</Label>
                                <Input
                                    id="lastName"
                                    name="lastName"
                                    placeholder="Perez"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                />
                                {errors.lastName && <p className="text-sm text-red-500">{errors.lastName}</p>}
                            </div>

                            <div className="space-y-2">
                                <Label htmlFor="phone">Teléfono</Label>
                                <Input
                                    id="phone"
                                    name="phone"
                                    type="tel"
                                    placeholder="10 digitos"
                                    value={formData.phone}
                                    onChange={handleChange}
                                />
                                {errors.phone && <p className="text-sm text-red-500">{errors.phone}</p>}
                            </div>
                            <div className="mt-3">

                                <label htmlFor="country">País</label>

                                <select
                                    id="country"
                                    name='country'
                                    value={formData.country || ""}
                                    onChange={handleChange}
                                    className="mt-1 p-3 block w-full rounded-md shadow-sm bg-gray-100 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">

                                    <option value="" disabled>Seleccionar</option>

                                    {countries.map((country, index) => (
                                        <option key={index} value={country}>
                                            {country}
                                        </option>
                                    ))}
                                </select>

                                {errors.country && <p className="text-sm text-red-500">{errors.country}</p>}
                            </div>

                            <div className="space-y-2">
                                <Label htmlFor="password">Contrase&ntilde;a</Label>
                                <Input
                                    id="password"
                                    name="password"
                                    type="password"
                                    placeholder="Contrasena"
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                                {errors.password && <p className="text-sm text-red-500">{errors.password}</p>}
                            </div>
                            <div className="space-y-2">
                                <Label htmlFor="confirmPassword">Repetir Contrase&ntilde;a</Label>
                                <Input
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    type="password"
                                    placeholder="Confirmar"
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                />
                                {errors.confirmPassword && <p className="text-sm text-red-500">{errors.confirmPassword}</p>}
                            </div>

                        </form>

                    </CardContent>

                    <CardFooter className="flex justify-end">

                        <Button disabled={isLoading} onClick={handleSubmit} className="border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                            <FontAwesomeIcon icon={faSave} /> {isLoading ? 'Enviando...' : 'Enviar'}
                        </Button>

                    </CardFooter>

                </Card>

            </main>

        </div>
    )

}

export default Register;